<template>
  <div>
    <header-slot> </header-slot>
    <div class="d-flex justify-content-end align-items-center py-1">
      <v-select
        v-model="selectYear"
        :reduce="(year) => year.id"
        :options="years"
        :selectable="(option) => option.selectable === true"
        :clearable="false"
        label="name"
        style="width: 150px"
        class="mr-1"
        @input="refreshDashboard()"
      />

      <v-select
        v-model="selectMonth"
        :reduce="(month) => month.id"
        :options="months"
        :clearable="false"
        label="name"
        style="width: 190px"
        @input="refreshDashboard()"
      />
    </div>
    <b-row>
      <b-col cols="6">
        <automatic-client-payments
          :key="keyAutomaticClientPayments"
          :select-year="selectYear"
          :select-month="selectMonth"
          :program-id="programId"
        />
      </b-col>
      <b-col cols="6" v-if="isCeo || isChief || isSupervisor">
        <call-analysis
          :select-year="selectYear"
          :select-month="selectMonth"
          :module-id="7"
        />
      </b-col>
      <b-col cols="6" class="mt-1" v-if="isCeo || isChief || isSupervisor">
        <DepartmentExpenses
          :showDate="true"
          :yearDash="selectYear"
          :monthDash="selectMonth"
        />
      </b-col>
    </b-row>
  </div>
</template>
<script>
import AutomaticClientPayments from "@/views/commons/components/payments/views/components/AutomaticClientPayments.vue";
import CallAnalysis from "@/views/ceo/dashboard/CallAnalysis.vue";
import DepartmentExpenses from "@/views/commons/expenses/components/department/DepartmentExpenses.vue";

export default {
  name: "BcDashboard",
  components: {
    AutomaticClientPayments,
    CallAnalysis,
    DepartmentExpenses,
  },
  data() {
    return {
      keyAutomaticClientPayments: 0,
      selectMonth: null,
      selectYear: null,
      optionsMonth: [
        { id: 1, name: "January" },
        { id: 2, name: "February" },
        { id: 3, name: "March" },
        { id: 4, name: "April" },
        { id: 5, name: "May" },
        { id: 6, name: "June" },
        { id: 7, name: "July" },
        { id: 8, name: "August" },
        { id: 9, name: "September" },
        { id: 10, name: "October" },
        { id: 11, name: "November" },
        { id: 12, name: "December" },
      ],
      optionsYears: [],
      today: new Date(),
    };
  },
  computed: {
    moduleId() {
      return this.$route.matched[0].meta.module;
    },
    programId() {
      return this.$route.matched[0].meta.program;
    },
    months() {
      if (this.selectYear === this.today.getFullYear()) {
        return this.optionsMonth.filter(
          (month) => month.id <= this.today.getMonth() + 1
        );
      }
      return this.optionsMonth;
    },
    years() {
      // Reset selectable mode for all years
      this.optionsYears.forEach((year) => {
        year.selectable = true;
      });

      // Disable future years if the selected month is greater than the current month
      const currentMonth = this.today.getMonth() + 1;
      if (this.selectMonth > currentMonth) {
        const lastYearIndex = this.optionsYears.length - 1;
        this.optionsYears[lastYearIndex].selectable = false;
      }

      return this.optionsYears;
    },
  },
  created() {
    this.generateYears();
    this.currentMonth();
  },
  mounted() {},
  methods: {
    generateYears() {
      const today = new Date();
      const currentYear = today.getFullYear();
      const beginningYear = 2023;
      this.optionsYears = [];

      for (let i = beginningYear; i <= currentYear; i++) {
        this.optionsYears.push({ id: i, name: i, selectable: true });
      }

      this.selectYear = currentYear;
    },
    currentMonth() {
      const today = new Date();
      const currentMonth = today.getMonth() + 1;
      this.selectMonth = currentMonth;
    },
    refreshDashboard() {
      this.keyAutomaticClientPayments++;
    },
  },
};
</script>
<style lang="scss">
</style>
